import {
    HasSessionFailureResponse,
    HasSessionSuccessResponse,
    Identity,
} from '@schibsted/account-sdk-browser/identity';
import ClientActions from './client-actions';

type HasSessionResponse = HasSessionSuccessResponse | HasSessionFailureResponse;
export default class SchibstedAccountSessionHandler {
    private readonly identity: Identity;

    private readonly clientActions: ClientActions;

    constructor(identity: Identity, clientActions: ClientActions) {
        this.identity = identity;
        this.clientActions = clientActions;
    }

    private static isHasSessionFailureResponse(
        response: HasSessionResponse,
    ): response is HasSessionFailureResponse {
        return (response as HasSessionFailureResponse).error !== undefined;
    }

    public refreshSpidSession(): void {
        this.identity
            .hasSession()
            .then((response) => {
                // Log success or failure.
                if (
                    SchibstedAccountSessionHandler.isHasSessionFailureResponse(
                        response,
                    )
                ) {
                    this.clientActions.track('FailureResponse');
                } else {
                    this.clientActions.track('SuccessResponse');
                }
            })
            .catch((exception) => {
                // This error is so common it spams trackjs, ignore it
                if (exception.message !== 'HasSession failed') {
                    console.error(exception);
                }
                this.clientActions.track('ErrorResponse');
            });
    }
}
